import axios from "axios";
import { jwtDecode } from "jwt-decode";

// const BASE_URL = "https://api.suspro.io/api/v1/"
// for local use 
const BASE_URL = "https://amoebaeffect.sustainabilitypro.io/api/v1"

// for server 
//const BASE_URL = `https://${window.location.host}/api/v1/`

// for local
// const BASE_URL = "http://localhost:3000/api/v1/"

const axiosInstance = axios.create({
    baseURL: BASE_URL,
});

axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem('amoeba_token');

    if (token) {
        const decodedToken = jwtDecode(token);
        const expirationTime = decodedToken.exp;
        const currentTime = Math.floor(Date.now() / 1000);

        if (expirationTime > currentTime) {
            config.headers['Authorization'] = `Bearer ${token}`;
            return config;
        } else {
            window.location.href = '/';
        }
    } else {
        window.location.href = '/';
    }
});

const fetchApi = async (query) => {
    try {
        const res = await axiosInstance.get(query);
        return res
    } catch (error) {
        console.error(error);
    }
}

const fetchApiWithoutAuth = async (query) => {
    try {
        const res = await axios.get(`${BASE_URL}/${query}`)
        return res
    } catch (error) {
        console.error(error);
    }
}

const exportToExcel = async (API, fileName) => {

    try {
        const res = await axiosInstance.get(API,
            { responseType: 'arraybuffer', });
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;

        // Specify the filename and extension
        link.setAttribute('download', `${fileName}.xlsx`);
        document.body.appendChild(link);
        link.click();
    } catch (error) {
        console.error(error);
    }
}

export default fetchApi;

export {
    exportToExcel,
    fetchApiWithoutAuth
}
import axios from "axios";
import { jwtDecode } from "jwt-decode";

// const API_URL = "https://api.suspro.io/api/v1"
// for local use 
const API_URL = "https://amoebaeffect.sustainabilitypro.io/api/v1"

// for server 
//const API_URL = `https://${window.location.host}/api/v1`

// for local
// const API_URL = "http://localhost:3000/api/v1"

const axiosInstance = axios.create({
    baseURL: API_URL,
});

axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem('amoeba_token');
    if (token) {
        const decodedToken = jwtDecode(token);
        const expirationTime = decodedToken.exp;
        const currentTime = Math.floor(Date.now() / 1000);

        if (expirationTime > currentTime) {
            config.headers['Authorization'] = `Bearer ${token}`;
            return config;
        } else {
            window.location.href = '/';
        }
    } else {
        window.location.href = '/';
    }
});

const uploadFile = (uploadData, inputName) => {
    try {
        const { seller_id, seller_name, year, templateFile } = uploadData;

        let formData = new FormData();
        formData.append("templateFile", templateFile);
        formData.append("seller_id", seller_id);
        formData.append("seller_name", seller_name);
        formData.append("year", year);


        return axiosInstance.post(`/${inputName}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress: progressEvent => {
                const percentage = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
            }
        });

    } catch (error) {
        console.error(error);
    }

};


const uploadFileData = (uploadData, inputName) => {
    try {

        const { data_point_id, amount, energy, emission, facility_id, month, year, attachment } = uploadData;

        let formData = new FormData();

        formData.append("data_point_id", data_point_id);
        formData.append("amount", amount);
        formData.append("energy", energy);
        formData.append("emission", emission);
        formData.append("facility_id", facility_id);
        formData.append("month", month);
        formData.append("year", year);
        formData.append("attachment", attachment);

        return axiosInstance.post(`/${inputName}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress: progressEvent => {
                const percentage = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
            }
        });

    } catch (error) {
        console.error(error);
    }

};

const update = async (data, query) => {

    try {
        const res = await axiosInstance.put(`/${query}`, data);
        return res.data;
    } catch (error) {
        console.error(error);
    }
};

const updatePost = async (data, query) => {

    try {
        const res = await axiosInstance.post(`/${query}`, data);
        return res.data;
    } catch (error) {
        console.error(error);
    }
};

const create = async (data, query) => {

    try {
        const res = query === 'login' || query === 'register'
        ? await axios.post(`${API_URL}/${query}`, data)
        : await axiosInstance.post(`/${query}`, data);
        return res.data;
    } catch (error) {
        return error.response ? error.response.data : { error: "An unknown error occurred" };
    }
};

const remove = async (query) => {
    try {
        const res = await axiosInstance.delete(`/${query}`);
        return res.data;
    } catch (error) {
        console.error(error);
    }
};

export default uploadFile;
export {
    update,
    create,
    updatePost,
    uploadFileData,
    remove
}
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, FacebookAuthProvider, signInWithPopup } from "firebase/auth"; 
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBg0KfaU2TQ13BAJCDYegVByce214TRtZw",
  authDomain: "my-app-a66e8.firebaseapp.com",
  projectId: "my-app-a66e8",
  storageBucket: "my-app-a66e8.appspot.com",
  messagingSenderId: "845550744773",
  appId: "1:845550744773:web:0c262ffa4a93b5b5682cac",
  measurementId: "G-46GBSPM491"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// Providers for social login
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

export { auth, googleProvider, facebookProvider, signInWithPopup };

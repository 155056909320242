import { jwtDecode } from 'jwt-decode';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const AuthRoute = ({ children, ...rest }) => {
    const token = localStorage.getItem('amoeba_token');

    const isTokenValid = () => {
        if (token) {
            const decodedToken = jwtDecode(token);
            const expirationTime = decodedToken.exp;
            const currentTime = Math.floor(Date.now() / 1000);
            return expirationTime > currentTime;
        }
        return false;
    };
    return isTokenValid() ? <Outlet /> : <Navigate to="/" />;
};

export default AuthRoute;

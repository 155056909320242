import React, { useState, useRef, useEffect } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { create } from "../services/upload-files.service";
import { Link } from "react-router-dom";
import useFetch from "../hooks/useFetch";

export default function Homepage() {
  const [tags, setTags] = useState([]);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    date: new Date().toISOString().slice(0, 10),
    type: '',
  });
  const [inputValue, setInputValue] = useState("");
  const [emailError, setEmailError] = useState("");
  const tagInputRef = useRef(null);
  const tagAreaRef = useRef(null);
  const labelRef = useRef(null);
  const [validationErrors, setValidationErrors] = useState({});
  const [show, setShow] = useState(false);
  const { data, loading } = useFetch('get-connection-by-id')
  const [treeData, setTreeData] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [visibleStartIndex, setVisibleStartIndex] = useState({});

  useEffect(() => {
    setTreeData(data);
  }, [data]);

  const renderTreeNode = (node, currentLevel = 1, isRoot = false) => {
    const maxLevel = 3;
    const maxHorizontalNodes = 5;

    const hasSingleChild = node.children && node.children.length === 1;

    if (!expanded && currentLevel > maxLevel) {
      return (
        <div className="more-button" key={node.id}>
          <button onClick={() => setExpanded(true)}>See More</button>
        </div>
      );
    }

    if (visibleStartIndex[node.id] === undefined) {
      setVisibleStartIndex((prev) => ({ ...prev, [node.id]: 0 }));
    }

    return (
      <div className={`tree-node ${isRoot ? 'root-node' : ''}`} key={node.id} style={{ marginBottom: '10px' }}>
        <div className="node">
          <div className="node-content">
            <p>{node.name}</p>
            <p>Help Area: {node.help_area}</p>
          </div>
        </div>

        {node.children && node.children.length > 0 && (
          <div className="tree-children">
            {node.children.length === 1 && node.children.map((child) => (
              <div className="single-child" key={child.id}>
                {renderTreeNode(child, currentLevel + 1, false)}
              </div>
            ))}

            {node.children.length > 1 && (
              <>
                {/* Show arrows if there are multiple children */}
                {visibleStartIndex[node.id] > 0 && (
                  <button
                    className="arrow-button left-arrow"
                    onClick={() =>
                      setVisibleStartIndex((prev) => ({
                        ...prev,
                        [node.id]: Math.max(0, prev[node.id] - 1),
                      }))
                    }
                    style={{ marginRight: '10px' }}
                  >
                    &lt;
                  </button>
                )}

                {node.children
                  .slice(
                    visibleStartIndex[node.id],
                    visibleStartIndex[node.id] + maxHorizontalNodes
                  )
                  .map((child) => renderTreeNode(child, currentLevel + 1, false))}

                {visibleStartIndex[node.id] + maxHorizontalNodes < node.children.length && (
                  <button
                    className="arrow-button right-arrow"
                    onClick={() =>
                      setVisibleStartIndex((prev) => ({
                        ...prev,
                        [node.id]: prev[node.id] + 1,
                      }))
                    }
                    style={{ marginLeft: '10px' }}
                  >
                    &gt;
                  </button>
                )}
              </>
            )}
          </div>
        )}

        {expanded && currentLevel === maxLevel + 1 && (
          <div className="close-button" style={{ marginTop: '5px' }}>
            <button onClick={() => setExpanded(false)}>Close</button>
          </div>
        )}
      </div>
    );
  };


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleLogout = () => {
    localStorage.removeItem('amoeba_token');
    localStorage.removeItem('email');
  };

  const handleTagAreaClick = () => {
    tagInputRef.current.focus();
  };

  const handleFocus = () => {
    tagAreaRef.current.classList.add("active");
    labelRef.current.classList.add("label-active");
  };

  const handleBlur = () => {
    tagAreaRef.current.classList.remove("active");
    if (inputValue === "" && tags.length === 0) {
      labelRef.current.classList.remove("label-active");
    }
    if (!inputValue.match(/^\s+$/gi) && inputValue !== "") {
      setTags((prevTags) => [...prevTags, inputValue.trim()]);
      setInputValue("");
    }
  };

  const handleKeyDown = (e) => {
    const value = e.target.value;
    if (
      (e.keyCode === 32 || e.keyCode === 13 || value[value.length - 1] === " ") &&
      !value.match(/^\s+$/gi) &&
      value !== ""
    ) {
      setTags((prevTags) => [...prevTags, value.trim()]);
      setInputValue("");
    }
    if (e.keyCode === 8 && value === "") {
      setTags((prevTags) => prevTags.slice(0, -1));
    }
  };

  const handleRemoveTag = (indexToRemove) => {
    setTags((prevTags) =>
      prevTags.filter((_, index) => index !== indexToRemove)
    );
  };

  useEffect(() => {
    const tagAreaElement = tagAreaRef.current;
    tagAreaElement.addEventListener("click", handleTagAreaClick);

    return () => {
      tagAreaElement.removeEventListener("click", handleTagAreaClick);
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // Email validation function
  const validateEmails = (emails) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regex for basic email validation
    for (let email of emails) {
      if (!emailRegex.test(email)) {
        return false; // Return false if any email is invalid
      }
    }
    return true;
  };

  const handleSendInvite = async (e) => {
    e.preventDefault();

    // Validate the email tags
    if (tags.length === 0) {
      showError("Please add at least one email.");
      return;
    }

    if (!validateEmails(tags)) {
      showError("One or more emails are invalid. Please enter valid emails.");
      return;
    }

    // Clear any previous errors
    clearError();

    const emails = {
      "invited_email": tags
    };

    try {
      const res = await create(emails, "users/add-user");

      if (res && res.msg === 'user already exists') {
        showError("This email already exists. Please enter a different one.");
        return;
      }

      if (res && res.affectedRows > 0) {
        showSuccess("Successfully Sent Invitation");
        clearFields();
      } else {
        showError("Please enter valid information");
      }
    } catch (error) {
      console.error("Error occurred:", error);
      showError("An error occurred. Please try again later.");
    }
  };

  // Helper functions
  const showError = (message) => {
    setEmailError(message);
    setValidationErrors({});

    setTimeout(() => {
      clearError();
    }, 3000);
  };

  const showSuccess = (message) => {
    setValidationErrors({ success: message });

    setTimeout(() => {
      setValidationErrors({});
    }, 3000);
  };

  const clearError = () => {
    setEmailError("");
    setValidationErrors({});
  };

  const clearFields = () => {
    setTags([]);
    setInputValue("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateFormFields(formData);
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
    } else {
      try {
        const res = await create(formData, "create-help");
        if (res.length > 0) {
          setValidationErrors({ success: "Successfully Help Area Created" });
          setShow(false);
        } else {
          setValidationErrors({ err: "Please enter valid information" });
        }
      } catch (error) {
        setValidationErrors({
          err: "An error occurred. Please try again later.",
        });
      }
      setFormData({});
    }
  };

  const validateFormFields = (formData) => {
    const errors = {};
    if (!formData.title || formData.title.trim() === "") {
      errors.title = "Please enter title";
    }

    if (!formData.description || formData.description.trim() === "") {
      errors.description = "Please enter description";
    }

    if (!formData.date || formData.date.trim() === "") {
      errors.date = "Please enter a date";
    }

    if (!formData.type || formData.type.trim() === "") {
      errors.type = "Please select type";
    }

    return errors;
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Container>
        <Row>
          <Col>
            <div className="homepage">
              <h1>Amoeba</h1>
              <h3>Add Mentor / Mentees</h3>
              <button><Link to='/' onClick={handleLogout} className="text-white">Sign out</Link></button>
              <button onClick={handleShow} className="me-2">Create Help</button>
              <div className="tag-area" ref={tagAreaRef}>
                <label className="label" htmlFor="tag-input" ref={labelRef}>
                  Add Emails
                </label>
                <ul>
                  {tags.map((tag, index) => (
                    <li key={index} className="tag">
                      {tag}
                      <span
                        className="cross"
                        onClick={() => handleRemoveTag(index)}
                      >
                        &nbsp;
                      </span>
                    </li>
                  ))}
                  <li>
                    <input
                      type="text"
                      className="tag-input"
                      id="tag-input"
                      ref={tagInputRef}
                      value={inputValue}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      onChange={(e) => setInputValue(e.target.value)}
                      onKeyDown={handleKeyDown}
                    />
                  </li>
                </ul>
                <button onClick={handleSendInvite} className="sendinvite">
                  Send Invite
                </button>
              </div>
              {emailError && <p className="tag-error">{emailError}</p>}
              {validationErrors.err && <p className="tag-error">{validationErrors.err}</p>}
              {validationErrors.success && <p className="tag-success">{validationErrors.success}</p>}

              <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Create Help</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <form onSubmit={handleSubmit}>
                    <label>Title</label>
                    <input
                      type="text"
                      name="title"
                      value={formData.title || ''}
                      onChange={handleInputChange}
                    />

                    <label>Description</label>
                    <textarea
                      rows="3"
                      name="description"
                      value={formData.description || ''}
                      onChange={handleInputChange}
                    />

                    <label>Date</label>
                    <input
                      type="date"
                      name="date"
                      placeholder="DateRange"
                      value={formData.date || ''}
                      onChange={handleInputChange}
                    />

                    <label className="mt-2">Help Type</label>
                    <div className="radiogroup">
                      <label>
                        <input
                          name="type"
                          type="radio"
                          value="Mentor"
                          checked={formData.type === "Mentor"}
                          onChange={handleInputChange}
                        /> Mentor
                      </label>
                      <label>
                        <input
                          name="type"
                          type="radio"
                          value="Mentees"
                          checked={formData.type === "Mentees"}
                          onChange={handleInputChange}
                        /> Mentees
                      </label>
                    </div>
                    <button type="submit" className="modalbutton">Save Changes</button>
                  </form>
                </Modal.Body>
              </Modal>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Container>
              <h3 className="tree-heading">User List</h3>
              {treeData ? renderTreeNode(treeData, 1, true) : <p>Loading...</p>}
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
}

import { Outlet } from "react-router";


const DefaultContainer = () => {
    return (
        <>
            <Outlet />
        </>
    )
}

export default DefaultContainer;
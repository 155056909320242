import axios from "axios";
import React, { useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [validationErrors, setValidationErrors] = useState({});

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');
    setValidationErrors({});
    const errors = validateFormFields(email);
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
    } else {
      try {
        const response = await axios.post('https://amoebaeffect.sustainabilitypro.io/api/v1/forgot-password', { email });
        setMessage(response.data.message);
        setEmail('')
      } catch (err) {
        setError(err.response?.data?.error || 'Something went wrong, try again.');
      }
    }
  };

  const validateFormFields = (formData) => {
    const errors = {};

    if (!email || email.trim() === "") {
      errors.email = "Please enter an email address";
    } else if (!validateEmail(email)) {
      errors.email = "Please enter a valid email address";
    }
    // else if (!isAllowedDomain(email)) {
    //   errors.email = "Email domain is not allowed";
    // }
    return errors;
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const isAllowedDomain = (email) => {
    const allowedDomains = ["gmail.com", "yahoo.com", "outlook.com"];
    const domain = email.split('@')[1];
    return allowedDomains.includes(domain);
  };

  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col sm="4">
            <form className="forgotpasswordform" onSubmit={handleForgotPassword}>
              <h3>Forgot Password</h3>
              <input
                type="email"
                placeholder="Enter registered email id"
                className={`form-control ${validationErrors.email ? 'is-invalid' : ''}`}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {validationErrors.email && (
                <div className="invalid-feedback">{validationErrors.email}</div>)
              }
              <label>
                Reset Password link will be sent to this you registered email
              </label>
              <Link to="../" relative="path" className="backbtn">Back</Link>
              <button className="modalbutton" type="submit">Submit</button>
            </form>
            {/* {validationErrors && <p>{validationErrors.email}</p>} */}
            {message && <p className="success-message">{message}</p>}
            {error && <p className="error-message">{error}</p>}
          </Col>
        </Row>
      </Container>
    </>
  );
}

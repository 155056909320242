import "./App.css";
import AuthRoute from "./AuthRoute";
import DefaultContainer from "./components/DefaultContainer";
import Register from "./components/Register";
import ForgotPassword from "./components/ForgotPassword";
import Homepage from "./components/Homepage";
import { Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import ResetPassword from "./components/ResetPassword";


function App() {
  return (
    <>
      <Routes>
        <Route element={<AuthRoute />}>
          <Route element={<DefaultContainer />}>
            <Route path="/homepage" element={<Homepage />} />
          </Route>
        </Route>
        <Route path="/" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
      </Routes>
    </>
  );
}

export default App;

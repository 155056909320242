import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Col, Container, Row } from 'react-bootstrap';

const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');

    const navigate = useNavigate();
    const location = useLocation();

    const token = new URLSearchParams(location.search).get('token');

    const handleResetPassword = async (e) => {
        e.preventDefault();
        setMessage('');
        setError('');
        setPasswordError('');
        setConfirmPasswordError('');

        const passwordValidationError = validatePassword(password);
        if (passwordValidationError) {
            setPasswordError(passwordValidationError);
            return;
        }

        if (password !== confirmPassword) {
            setConfirmPasswordError('Passwords do not match!');
            return;
        }

        try {
            const response = await axios.post('https://amoebaeffect.sustainabilitypro.io/api/v1/reset-password', {
                token,
                password
            });
            setMessage(response.data.message);
            setTimeout(() => {
                navigate('/');
            }, 3000);
        } catch (err) {
            setError(err.response?.data?.error || 'Something went wrong, try again.');
        }
    };

    const validatePassword = (password) => {
        const minLength = 8;
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumber = /\d/.test(password);
    
        if (password.length < minLength) {
            return `Password must be at least ${minLength} characters.`;
        }
        if (!hasUpperCase) {
            return 'Password must contain at least one uppercase letter.';
        }
        if (!hasLowerCase) {
            return 'Password must contain at least one lowercase letter.';
        }
        if (!hasNumber) {
            return 'Password must contain at least one number.';
        }
    
        return ''; 
    };    

    return (
        <Container>
            <Row className="justify-content-center">
                <Col sm="4">
                    <div className="forgotpasswordform">
                        <h3>Reset Password</h3>
                        <form onSubmit={handleResetPassword}>
                            <label htmlFor="password">New Password</label>
                            <input
                                type="password"
                                id="password"
                                className={`form-control ${passwordError ? 'is-invalid' : ''}`}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            {passwordError && (
                                <div className="invalid-feedback">{passwordError}</div>
                            )}
                            <label htmlFor="confirm-password">Confirm New Password</label>
                            <input
                                type="password"
                                id="confirm-password"
                                className={`form-control ${confirmPasswordError ? 'is-invalid' : ''}`}
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                            />
                            {confirmPasswordError && (
                                <div className="invalid-feedback">{confirmPasswordError}</div>
                            )}
                            <button className='modalbutton' type="submit">Reset Password</button>
                        </form>
                        {message && <p className="success-message">{message}</p>}
                        {error && <p className="error-message ">{error}</p>}
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default ResetPassword;
